import { React, useState, useEffect } from "react";
import "./homepage.css"; 

function Aboutpage() {
  const [isEng, setIsEng] = useState(null);
  useEffect(() => {
    const storedIsEng = localStorage.getItem("isEng");
    if (storedIsEng !== null) {
      setIsEng(storedIsEng === "true"); // Convert string to boolean
    }
  }, []);

  return (
    <div className="aboutpage  ">
      <div className="snap-center whitespace-pre-line text-center self-auto my-20 lg:text-4xl md:text-2xl sm:text-2xl whitespace-pre mx-10 ">
        {!isEng ? (
          <h3>“ สนันสนุนให้พันธกิจของลูกค้าสามารถบรรลุสู่ความสำเร็จได้ “</h3>
        ) : (
          <h3>“ Support the mission of the customer to achieve success. “</h3>
        )}
      </div>

      <div className="about-us-context  md:flex-col lg:flex items-center justify-between">
        <div className="page-info-text-content text-center items-center flex flex-col basis-1/2">
          <div className="mx-10 flex-col">
            <div className="snap-center  lg:text-4xl md:text-2xl sm:text-2xl whitespace-pre mx-10 ">
              {!isEng ? <h2>เกี่ยวกับเรา</h2> : <h2>About Us</h2>}
            </div>
            <div className="line my-10 mx-auto w-96"></div>

            <div className="snap-center mt-5 lg:text-3xl md:text-2xl sm:text-xl text-center whitespace-pre-line">
              <h4 className="snap-center font-bold  mx-10 ">
                บริษัท จีดับบลิวเอส รีเสิร์ช แอนด์ ดีเวลลอปเม้นท์ จำกัด
              </h4>
              {!isEng ? (
                <p4>
                  เป็นผู้ให้บริการกับโรงงาน ทางด้านผลิตและติดตั้งระบบอัตโนมัติ
                  สายพานลำเลียงทั่วไป ไลน์ผลิตโดยใช้หุ่นยนต์ รวมไปถึง AGV Robot
                  ในไลน์ผลิตอาหารและอิเล็กทรอนิคส์ เกษตรกรรม จัดหาอุปกรณ์
                  เครื่องมือ และ อะไหล่ที่ใช้ในโรงงาน ตามความต้องการของลูกค้า
                </p4>
              ) : (
                <p>
                  Being a service provider to the factory In the production and
                  installation of automation systems General conveyor belt
                  Production lines using robots, including AGV Robots In food
                  and electronics production lines, agriculture, equipment
                  procurement Tools and spare parts used in the factory
                  according to customer needs
                </p>
              )}
              <div className="mt-8 mx-auto w-96"></div>
              {!isEng ? (
                <p>
                  บริษัทเรามีความพร้อมและ
                  มีบุคลากรที่มีความเชี่ยวชาญในการให้บริการ
                  สามารถออกแบบให้เหมาะสมกับผลิตภัณฑ์ อีกทั้งยังสามารถติดตั้ง
                  ได้อย่างรวดเร็ว
                </p>
              ) : (
                <p>
                  Our company is ready and There are personnel with expertise in
                  providing services. Can be designed to suit the product It can
                  also be installed quickly
                </p>
              )}
            </div>
          </div>
        </div>
        {/* picture context */}
        <div className="page-info-pic   basis-1/2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/about_us%2Fimage%2010.webp?alt=media&token=d324a879-7d2c-44c4-a96b-f6b29b2e0c11"
            alt="เครื่องจักร, หุ่นยนต์, Lidar"
            lazyLoad="true"
          />
        </div>
      </div>

      <div className="about-us-context-2 mb-10 sm:mt-10 md:flex-col lg:flex items-center justify-between">
        {/* picture context */}
        <div className="page-info-pic basis-1/2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/about_us%2Fimage%2011.webp?alt=media&token=a5b61b8c-1bc8-43cc-aa26-4898df655616"
            alt="เครื่องจักร, หุ่นยนต์, Lidar"
            lazyLoad="true"
          />
        </div>

        <div className="page-info-text-content text-center items-center flex flex-col basis-1/2">
          <div className="mx-10 flex-col  ">
            <div className="snap-center mt-5 lg:text-3xl md:text-2xl sm:text-xl text-center whitespace-pre-line">
              <h3 className="snap-center font-bold  mx-10 ">
                รับออกแบบ ผลิต และติดตั้งระบบสายพานลำเลียง สายพานอัตโนมัติ
                ทุกขนาด ทุกแบบ
              </h3>
              <div className="mt-8 mx-auto w-96"></div>
              {!isEng ? (
                <p4>
                  ยินดีให้คำปรึกษารูปแบบ รุ่นและวัสดุที่ใช้ในการผลิต
                  เพื่อสะดวกและเหมาะสมกับการใช้งานในแบบต่าง ๆ
                  ทั้งลักษณะและจำนวนการผลิตของลูกค้า
                </p4>
              ) : (
                <p>
                  
Happy to give advice on formats. Model and materials used in production
                  To be convenient and suitable for use in various ways
                  both the nature and quantity of customer production
                </p>
              )}
              <div className="mt-8 mx-auto w-96"></div>
              {!isEng ? (
                <p>
                  รวมไปถึงระบบไฟ ตู้คอลโทรล รางไฟ เครื่องมือต่าง ๆ
                  ที่ใช้ภายในโรงงานเพื่อสร้างความพึงพอใจ
                  ช่วยให้ธุรกิจของลูกค้าเดินหน้าและ
                  เติบโตได้อย่างต่อเนื่องด้วยประสิทธิภาพสูงสุด
                </p>
              ) : (
                <p>
                  Including electrical systems, control cabinets, power rails, and various tools.
                  used within the factory to create satisfaction
                  Help customers' businesses move forward and
                  Continuously grow with maximum efficiency
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutpage;
