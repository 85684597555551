import React, { useEffect }  from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  Link,
  useNavigate,
} from "react-router-dom";
import NavigationBar from "./navigationBar";
import Homepage from "./pages/homepage";
import Aboutpage from "./pages/aboutpage";
import Contactpage from "./pages/contactpage";
import Productpage from "./pages/productpage";
import Footer from "./footer";
import Error from "./pages/error";
import ReactGA from "react-ga";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./firebase";
const TRACKING_ID = "G-8GPCEW6KQF"; // OUR_TRACKING_ID
 
ReactGA.initialize(TRACKING_ID);
const slideImages = [
  "https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/banner%2Fbanner%201.png?alt=media&token=e85644f1-549d-4c53-b674-a39da25fc5f5",
  "https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/banner%2Fbanner%202.png?alt=media&token=9fc3f2d4-5c20-47e9-9147-991dc02aed4d",
];
const logoUri = {
  s512: "https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/logo%2Flogo512.PNG?alt=media&token=1aa13fa1-1b7f-49d8-998a-ff0e8ec130c1",
  s192: "https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/logo%2Flogo192.PNG?alt=media&token=4f8e2cc8-d644-4c94-ba9a-11b73b36c237",
  s64: "https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/logo%2Ffavicon.PNG?alt=media&token=596fb170-bead-4fed-83e5-991966bc94ff",
};
const transitionDuration = 1000; 
function App() { 
  
  return (
    <BrowserRouter>
      <div className="flex items-center justify-center text-center">
        <div className="head-logo bg-gray-200 w-screen bg-contain bg-fixed">
          <div className="flex  flex-col items-center justify-center font-bold mx-20 text-white text-base text-center h-full">
            <img className="" alt="gws-rd-logo" src={logoUri.s512} />
            <h1 className="text-base text-gray-200">บจก.จีดับบลิวเอส รีเสิร์ช แอนด์ ดีเวลลอปเม้นท์</h1>
          </div>
        </div>  
      </div>
      <NavigationBar />
      <div className="carousel-container ">
        <Carousel
          autoPlay
          interval={5000}
          transitionTime={transitionDuration}
          infiniteLoop
          showThumbs={false} // Remove small thumbnails
          renderIndicator={(clickHandler, isSelected, index, label) => {
            return (
              <span
                onClick={clickHandler}
                key={index}
                style={{
                  background: isSelected ? "#0070f3" : "rgba(0, 0, 0, 0.3)",
                  width: "8px", // Adjust the width to set the circle size
                  height: "8px", // Adjust the height to set the circle size
                  display: "inline-block",
                  margin: "0 4px",
                  cursor: "pointer",
                  borderRadius: "50%", // Make the square shape a circle
                }}
              ></span>
            );
          }}
        >
          {slideImages.map((url, index) => (
            <div key={index}>
              <img src={url} alt={`Slide ${index}`} />
            </div>
          ))}
        </Carousel>
      </div>
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path="/contact" element={<Contactpage />} />
        <Route path="/products" element={<Productpage />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <footer className="footer ">
        <Footer />
      </footer>
    </BrowserRouter>
  );
}

export default App;
