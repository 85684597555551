import React, { Component } from 'react';
import { longdo, map, LongdoMap} from './LongdoMap';

 
class Longdomapcomp extends Component {
  
  initMap(){
    map.Layers.setBase(longdo.Layers.GRAY); 
    //map.Overlays.add(longdo.Marker({ lon: 100.6389203, lat: 13.5660202 }));
  }

  // make sure to use different map key: https://map.longdo.com/api
  render() {
    const mapKey = '7501a25dcdaa246856c870b2bcfdf96e'
    return (
      <div className="Map h-full my-10 mx-12">
        <LongdoMap id="longdo-map" mapKey={mapKey} callback={this.initMap} />
      </div>
    );
  }
}

export default Longdomapcomp;