import { React, useState, useEffect } from "react";

const EmailForm = () => {
    const [isEng, setIsEng] = useState(null); 
  
    useEffect(() => {
      const storedIsEng = localStorage.getItem("isEng");
      if (storedIsEng !== null) {
        setIsEng(storedIsEng === "true"); // Convert string to boolean
      }
    }, []);
  
  const [formData, setFormData] = useState({
    name: '',
    cell: '',
    lineId: '',
    email: '',
    questionType: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    console.log(formData);
  };

  return (
    <div className="max-w-md mx-10 mt-8 p-6 bg-white rounded w-full">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          {!isEng ? <p>ชื่อ-นามสกุล</p> : <p> Name</p>}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cell">
           {!isEng ? <p>หมายเลขโทรศัพท์</p> : <p> Cell</p>}
          </label>
          <input
            type="text"
            id="cell"
            name="cell"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lineId">
            Line ID
          </label>
          <input
            type="text"
            id="lineId"
            name="lineId"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="questionType">
            
            {!isEng ? <p>คำถาม</p> : <p>Type of Questions</p>}
          </label>
          <select
            id="questionType"
            name="questionType"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={handleChange}
          >
            <option value="" disabled>{!isEng ? <p>เลือกคำถาม</p> : <p>Select a type </p>}</option>
            <option value="po.req">{!isEng ? <p>สอบถามราคา</p> : <p>Quotations Request</p>}</option>
            <option value="poc.req">{!isEng ? <p>ขอทดสอบผลิตภัณฑ์</p> : <p>Proof of Concept Request</p>}</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            
            {!isEng ? <p>รายละเอียด</p> : <p>Description</p>}
          </label>
          <textarea
            id="description"
            name="description"
            rows="4"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={handleChange}
          ></textarea>
        </div>

        <button
          type="submit"
          className=" w-full  text-white p-2 rounded bg-red-500 hover:bg-red-600"
        >
         
          {!isEng ? <p>ส่งคำถาม</p> : <p>Submit</p>}
        </button>
      </form>
    </div>
  );
};

export default EmailForm;
