import { React, useState, useEffect } from "react";
import MaterialIcon, { colorPalette } from "material-icons-react";
import GoogleMapReact from "google-map-react";
import { Loader } from "@googlemaps/js-api-loader";
import Longdomapcomp from "./components/longdomapcomp";

import { Link } from "react-router-dom";
import EmailForm from "./EmailForm";
import "./homepage.css";

const mapKey = `7501a25dcdaa246856c870b2bcfdf96e`;

const AnyReactComponent = ({ text }) => (
  <div className="flex">
    <Link to="https://www.google.co.th/maps/place/%E0%B8%9A%E0%B8%88%E0%B8%81.%E0%B8%88%E0%B8%B5%E0%B8%94%E0%B8%B1%E0%B8%9A%E0%B8%9A%E0%B8%A5%E0%B8%B4%E0%B8%A7%E0%B9%80%E0%B8%AD%E0%B8%AA+%E0%B8%A3%E0%B8%B5%E0%B9%80%E0%B8%AA%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%8A+%E0%B9%81%E0%B8%AD%E0%B8%99%E0%B8%94%E0%B9%8C+%E0%B8%94%E0%B8%B5%E0%B9%80%E0%B8%A7%E0%B8%A5%E0%B8%A5%E0%B8%AD%E0%B8%9B%E0%B9%80%E0%B8%A1%E0%B9%89%E0%B8%99%E0%B8%97%E0%B9%8C/@13.5659569,100.6364868,17z/data=!3m1!4b1!4m6!3m5!1s0x311d59b6e79cdaeb:0xd1d5e06e2d9d186b!8m2!3d13.5659517!4d100.6390671!16s%2Fg%2F11q1kkczk6?hl=th&entry=ttu">
      <div>
        <MaterialIcon color="#EA4335" icon="location_on" size={64} />
      </div>
    </Link>
  </div>
);

const AddressInfo = () => (
  <div className="flex justify-center items-center mx-10">
    {/* ... address info and Facebook icon ... */}
    <div className=" flex flex-col items-center">
      <div className="flex flex-col mx-4 mt-5 items-center">
        <img
          alt="gws-logo"
          src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/logo%2Flogo512.PNG?alt=media&token=1aa13fa1-1b7f-49d8-998a-ff0e8ec130c1"
        />
        <h1 className="text-xl text-black py-4">
          บจก.จีดับบลิวเอส รีเสิร์ช แอนด์ ดีเวลลอปเม้นท์
        </h1>
        <div className="flex justify-center mt-10">
          <p className="mx-4 flex-wrap text-center">
            1233 66 ซอย แพรกษา 8 ตำบล แพรกษา อำเภอเมืองสมุทรปราการ สมุทรปราการ
            10280
          </p>
        </div>
        <div className="flex  mt-10">
          <Link
            to="tel:+66800741888"
            className="mx-4 font-bold hover:underline underline-offset-4"
          >
            080 074 1888
          </Link>
        </div>
        <div className="flex  mt-10">
          <Link
            to="mailto:sales_admin@gws-rd.com"
            className="mx-4 hover:underline underline-offset-4"
          >
            sales_admin@gws-rd.com
          </Link>
        </div>
      </div>

      <div
        className="facebook-icon my-10 py-5 flex items-center h-16 bg-blue-600 text-black hover:text-blue-600 hover:bg-blue-800"
        style={{ width: 300 }}
      >
        <Link
          to="https://www.facebook.com/1665740753644385"
          className="more-info my-10  flex items-center justify-center h-14"
          style={{ width: 300 }}
        >
          {/* Add your Facebook icon here */}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FFacebook%20logo.webp?alt=media&token=ce310694-0ca4-4331-89d8-5dca1551a745"
            alt="facebook-logo"
            lazyLoad="true"
          />
          <div className="ml-5 text-xl text-white font-regular">
            <p>สนใจบริการปรึกษาฟรี</p>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

function Contactpage() {
  const [isEng, setIsEng] = useState(null);

  const defaultProps = {
    center: {
      lat: 13.5660202,
      lng: 100.6389203,
    },
    zoom: 15,
  };

  useEffect(() => {
    const storedIsEng = localStorage.getItem("isEng");
    if (storedIsEng !== null) {
      setIsEng(storedIsEng === "true"); // Convert string to boolean
    }
  }, []);

  return (
    <div className="contactpage ">
      <div className="mt-10 snap-center  text-center lg:text-4xl md:text-2xl sm:text-2xl whitespace-pre mx-10">
        {!isEng ? <h2>ติดต่อเรา</h2> : <h2>Contact Us</h2>}
      </div>
      <div className="line my-10 mx-auto w-96 " />

      <div className=" flex flex-col justify-center sm:flex-row sm:justify-around sm:items-start items-center">
        <AddressInfo />
        <EmailForm />
      </div>

      <div className="mt-5" style={{ height: "75vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAKvWbls8l0xGruHc7lN4gpbCeYvtgZ1sc", language: "th", region: "th" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent 
            text="บจก.จีดับบลิวเอส รีเสิร์ช แอนด์ ดีเวลลอปเม้นท์"
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default Contactpage;
