import React,{ useEffect }  from "react";
import NavigationBar from "./navigationBar";
import { Link } from "react-router-dom";
import MaterialIcon, { colorPalette } from "material-icons-react";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // For smooth scrolling, if supported by the browser
  });
};

function Footer() { 
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  return (
    <div className="footer items-center bg-gray-200 flex flex-col justify-center sm:min-w-full ">
      <div className="flex flex-col mx-4 mt-5 items-center justify-center">
        <img alt="gws-log" src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/logo%2Flogo192.PNG?alt=media&token=4f8e2cc8-d644-4c94-ba9a-11b73b36c237"/> 
         <h3 className="text-xl text-black pt-10">บจก.จีดับบลิวเอส รีเสิร์ช แอนด์ ดีเวลลอปเม้นท์</h3>
        </div>
      <div className="text-left   text-black w-96 text-xl">
        
        <div className="flex  mt-10">
          <MaterialIcon color="#1672EA" icon="location_on" size={32} />
          <p className="mx-4 flex-wrap ">
            1233 66 ซอย แพรกษา 8 ตำบล แพรกษา อำเภอเมืองสมุทรปราการ สมุทรปราการ
            10280
          </p>
        </div>
        <div className="flex">
          <MaterialIcon color="#1672EA" icon="call" size={32} />
          <p className="mx-4">080 074 1888 </p>
        </div>
        <div className="flex">
          <MaterialIcon color="#1672EA" icon="email" size={32} />
          <p className="mx-4">sales_admin@gws-rd.com</p>
        </div>
      </div>

      <div
        className="facbook-icon my-10 py-5 flex items-center h-16 bg-red-600 text-black hover:text-blue-600 hover:bg-red-800"
        style={{ width: 300 }}
      >
      
        <Link
               to="https://www.facebook.com/1665740753644385"
               className="more-info my-10  flex items-center justify-center h-14"
              style={{ width: 300 }}
            ><img
            src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FFacebook%20logo.webp?alt=media&token=ce310694-0ca4-4331-89d8-5dca1551a745"
            alt="facebook-logo"
            lazyLoad="true"
          />
              <div className="ml-5 text-xl text-white font-regular">
                <p>สนใจบริการปรึกษาฟรี</p>
              </div>
            </Link>
      </div>
      <div className="flex text-black   ">
        <button className="mt-4 flex hover:bg-gray-200" onClick={scrollToTop}>
          <MaterialIcon color="#1672EA" icon="arrow_circle_up" size={32} />
          <p className="ml-4 mt-1">Go to Top</p>
        </button> 
      </div>
      <div className="mt-10 copyright text-black text-center ">
      <h3 className="pt-10">บจก.จีดับบลิวเอส รีเสิร์ช แอนด์ ดีเวลลอปเม้นท์</h3>
        <p>Copyright © 2023 gws-rd.com | All rights reserved</p>
      </div>
    </div>
  );
}

export default Footer;
