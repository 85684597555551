import { React, useState, useEffect } from "react";
import "./homepage.css";
import ProductShowcase from "./components/productshowcase";
import { Link } from "react-router-dom";

const products = [
  {
    name: `Conveyer/Customize`,
    des: [
      `สายพานลำเลียง`,
      `เครื่องลพเลียงสินค้าแบบสายพาน`,
      `เพื่อลำเลียงสินค้าไปอีกที่หนึ่ง`,
      `ลดการใช้แรงงานคน`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FConveyer-Customize.webp?alt=media&token=d26ab69d-ee49-41fc-9e7f-1fd34e531b00`,
    width_card: 350,
  },
  {
    name: `Vibrated Conveyer`,
    des: [
      `สำหรับ Bulk Material`,
      ` ชนิดผง เมล็ด เกล็ดต่าง ๆ`,
      `ครีมเทียม นมผง แป้ง `,
      `เกล็ดขนมปัง ผงปรุงรส`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FVibrater%20conveyer.webp?alt=media&token=9ada9027-efd2-4f9f-93ab-9c14a57207ed`,
    width_card: 350,
  },
  {
    name: `AGV Robot/Customize`,
    des: [
      `Automated Guided`,
      `Vehicle (AGV)`,
      `หุ่นยนต์อัตโนมัติในการบริการจัด`,
      `การสินค้าไม่จำเป็นต้องมีผู้ควบคุม`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FAGV-robotCustomize.webp?alt=media&token=aec1e1ea-e85a-4566-aaf3-4db2961a9691`,
    width_card: 350,
  },
  {
    name: `Pelletizes`,
    des: [
      `หุ่นยนต์ลำเลียงพาเลทอัตโนมัติ`,
      `ช่วยจัดเรียงสินค้าขึ้นพาเลท`,
      `เพื่อความรวดเร็ว`,
      `และแม่นยำในการบรรจุ`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2Fpalletizer.webp?alt=media&token=0d6df4d0-4d17-416b-b890-16baf80c297a`,
    width_card: 350,
  },
];

const showcase_project = [
  {
    name: `เครื่อง CVC ยกกล่องขึ้นสายพานหลัก`,
    des: [`AB Food&Beverages Ltd 2020`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2F%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%20cvc%20%E0%B8%A2%E0%B8%81%E0%B8%81%E0%B8%A5%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%82%E0%B8%B6%E0%B9%89%E0%B8%99%E0%B8%AA%E0%B8%B2%E0%B8%A2%E0%B8%9E%E0%B8%B2%E0%B8%99%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%81.webp?alt=media&token=7ab3b063-5160-4f3a-b143-7cd1bc8dd6cc`,
    width_card: 350,
  },
  {
    name: `Merge Conveyor System`,
    des: [`AB Food&Beverages Ltd 2020`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FMerge%20conveyor%20system.webp?alt=media&token=01303d83-9bba-4240-af27-427906df425b`,
    width_card: 350,
  },
  {
    name: `ระบบนับสินค้าแยกประเภท ขาออกโดยใช้บาร์โค้ด`,
    des: [`บริษัท เบอร์รี่ยุกเกอร์ฟู้ดส์ จำกัด 2020`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2F%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B1%E0%B8%9A%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%A0%E0%B8%97.webp?alt=media&token=42d90e97-4701-4c20-8bb0-9d7551bb609b`,
    width_card: 350,
  },
  {
    name: `สายพานเรียงขนมตามจังหวะ เครื่องแพ็ค`,
    des: [`บริษัท เบอร์รี่ยุกเกอร์ฟู้ดส์ จำกัด 2020`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2F%E0%B8%AA%E0%B8%B2%E0%B8%A2%E0%B8%9E%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B8%82%E0%B8%99%E0%B8%99%E0%B8%A1%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%88%E0%B8%B1%E0%B8%87%E0%B8%AB%E0%B8%A7%E0%B8%B0%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B9%81%E0%B8%9E%E0%B9%87%E0%B8%84.webp?alt=media&token=638fcff7-a233-4f38-9969-9af47d5538e1`,
    width_card: 350,
  },
];
const showcase_project2 = [
  {
    name: `Sorter 30 Gate Full Automation`,
    des: [`Thai optical group PCL 2019`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2F%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%20Sorter%2030%20gate%20full%20automation.webp?alt=media&token=60fe84ff-f083-4bb0-949f-1144c6498bea`,
    width_card: 350,
  },
  {
    name: `Oil Filling Automation`,
    des: [`บริษัท ซีพีแรม จำกัด 2019`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FOil%20Filling%20automation.webp?alt=media&token=051ff2bf-65b4-4549-9951-b3af6634b43a`,
    width_card: 350,
  },
  {
    name: `Robot ติดสติ๊กเกอร์ บาร์โค้ดอัตโนมัติ`,
    des: [`Ubc Chemicals Asia PCL 2021`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2Frebot%20%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%AA%E0%B8%95%E0%B8%B4%E0%B9%8A%E0%B8%81%E0%B9%80%E0%B8%81%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%9A%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B9%82%E0%B8%84%E0%B9%89%E0%B8%94%E0%B8%AD%E0%B8%B1%E0%B8%95%E0%B9%82%E0%B8%99%E0%B8%A1%E0%B8%B1%E0%B8%95%E0%B8%B4.webp?alt=media&token=6acf26e6-7085-4bbd-9417-93e1bdbd13bc`,
    width_card: 350,
  },
  {
    name: `เครื่องประกอบแตร์`,
    des: [`บริษัท นิกโก คอร์ปอเรชั่น จำกัด 2020`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2F%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%AD%E0%B8%9A%E0%B9%81%E0%B8%95%E0%B8%A3%E0%B8%A3%E0%B8%96%E0%B8%A2%E0%B8%99%E0%B8%95%E0%B9%8C%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%A3%E0%B8%96%E0%B8%88%E0%B8%B1%E0%B8%81%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B8%A2%E0%B8%99%E0%B8%95%E0%B9%8C.webp?alt=media&token=913cc7ad-8b3e-4380-bc82-230177821678`,
    width_card: 350,
  },
];
const buttons_picture_url =
  "https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/banner%2Fbanner%202.png?alt=media&token=9fc3f2d4-5c20-47e9-9147-991dc02aed4d";

const Homepage = () => {
  const [isEng, setIsEng] = useState(null);
  useEffect(() => {
    const storedIsEng = localStorage.getItem("isEng");
    if (storedIsEng !== null) {
      setIsEng(storedIsEng === "true"); // Convert string to boolean
    }
  }, []);

  return (
    <div>
      <div className="App-body">
        <div className="Homepage-content ">
          {/* Context  Zone*/}
          <div className="page-info my-20 sm:md:flex lg:flex   flex-row  justify-between items-center ">
            {/* text context */}
            <div className="page-info-text-content text-center items-center flex flex-col basis-1/3">
              <div className="mx-10 flex-col md:w-96 ">
                <div className="snap-center font-bold lg:text-4xl md:text-2xl sm:text-2xl whitespace-pre mx-10 ">
                  {!isEng ? <h3>วิสัยทัศน์</h3> : <h3>Vision</h3>}
                </div>
                <div className="line mt-4 mx-auto w-96"></div>
                <div className="snap-center mt-5 lg:text-4xl md:text-2xl sm:text-xl text-center whitespace-pre-line">
                  {!isEng ? (
                    <h3>
                      ออกแบบและสร้างระบบอัตโนมัติ ด้วยเทคโนโลยี AI
                      รองรับทั้งธุรกิจขนาดเล็ก ถึงโรงงานอุตสาหกรรม
                    </h3>
                  ) : (
                    <p>
                      Design and build automation systems with AI technology
                      Supports both small businesses to industrial plants
                    </p>
                  )}
                </div>
                <div className="snap-center mt-5 lg:text-2xl md:text-xl sm:text-l text-center whitespace-pre-line">
                  {!isEng ? (
                    <p>
                      มีอะไรอีกมากมายให้พัฒนา ทางด่านอุตสาหกรรม สนใจสอบถาม
                      เสนอแนวคิดพัฒนาธุรกิจของคุณได้
                    </p>
                  ) : (
                    <p>
                      There is much more to develop. At the industrial
                      checkpoint, if interested, inquire. Propose ideas for
                      developing your business.
                    </p>
                  )}
                </div>
              </div>
              <div
                className="facbook-icon my-10 py-5 flex items-center h-16 bg-blue-600 text-white  hover:bg-red-600"
                style={{ width: 300 }}
              >
                <Link
                  to="https://www.facebook.com/1665740753644385/posts/1714628442088949/"
                  className="more-info my-10 flex items-center justify-center h-14"
                  style={{ width: 300 }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FFacebook%20logo.webp?alt=media&token=ce310694-0ca4-4331-89d8-5dca1551a745"
                    alt="facebook-logo"
                    lazyLoad="true"
                  />
                  <div className="ml-5 text-xl font-regular">
                    {!isEng ? (
                      <p>สนใจบริการปรึกษาฟรี</p>
                    ) : (
                      <p>Free Consultation</p>
                    )}
                  </div>
                </Link>
              </div>
            </div>
            {/* picture context */}
            <div className="page-info-pic">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/banner%2Fimage%201.png?alt=media&token=d5740198-a7d6-4b43-b7f0-7d3cd76518fd"
                alt="เครื่อจักร, สายพานลำเลียง"
                lazyLoad="true"
              />
            </div>
          </div>

          {/* Product preview Zone*/}
          <div className="product-zone mt-10 my-20 w-full flex flex-col items-center justify-center text-center">
            <div className="snap-center text-center font-bold lg:text-4xl md:text-2xl sm:text-xl whitespace-pre">
              {!isEng ? <h3>งานระบบ และผลิตภัณฑ์</h3> : <h3> Our Products</h3>}
              <div className="line mt-4 mx-auto w-96"></div>
              <ProductShowcase products={products} />
            </div>
            <Link to="products">
              <div
                className="more-info my-10 bg-blue-600 flex items-center justify-center h-14 hover:bg-red-600"
                style={{ width: 300 }}
              >
                <div className="text-xl font-regular text-white">
                  {!isEng ? (
                    <p>ผลิตภัณฑ์เพิ่มเติม</p>
                  ) : (
                    <p> Additional Products</p>
                  )}
                </div>
              </div>
            </Link>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FBanner%20%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%88%E0%B8%B3%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%20ABB.webp?alt=media&token=e8bc490a-1917-411a-8625-d96eed484537"
              alt="ตัวแทนจำหน่ายสินค้า ABB, Robot Arm, หุ่นยนต์อุตสาหกรรม"
              lazyLoad="true"
            />
          </div>

          <div className="product-zone mt-10 my-20 w-full flex flex-col items-center justify-center text-center">
            <div className="snap-center text-center font-bold lg:text-4xl md:text-2xl sm:text-xl whitespace-pre">
              {!isEng ? <h3>ผลงานระบบและผลิตภัณท์</h3> : <h3> Results</h3>}
              <div className="line mt-4 mx-auto w-96"></div>
              <ProductShowcase products={showcase_project} />
              <ProductShowcase products={showcase_project2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
