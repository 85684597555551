import { React, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import MaterialIcon, { colorPalette } from "material-icons-react";

const NavigationBar = () => {
  const [isEng, setIsEng] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const activeClassName =
    "lg:text-2xl md:text-xl sm:text-xs text-black font-bold";
  const inactiveClassName =
    "lg:text-2xl md:text-xl sm:text-xs text-black hover:underline";

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 640); // You can adjust the threshold for mobile view
  };

  const changeLanguage = () => {
    const updatedIsEng = !isEng; // Toggle the value before updating
    setIsEng(updatedIsEng); // Update the state
    localStorage.setItem("isEng", updatedIsEng); // Set the localStorage with the updated value
    console.log(localStorage.getItem("isEng"));
    window.location.reload();
  };

  useEffect(() => {
    const storedIsEng = localStorage.getItem("isEng");
    if (storedIsEng !== null) {
      setIsEng(storedIsEng === "true"); // Convert string to boolean
    }
    handleResize(); // Initial check on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <nav className="bg-navy p-4 bg-gray-200 bg-contain bg-local text-black">
      <div className="absolute top-9 right-2">
        <button onClick={toggleDrawer} className="lg:hidden ">
          
            <MaterialIcon color="#1672EA" icon="menu" size={50} />
          
        </button>
      </div>

      {drawerOpen && (
        <ul className="lg:hidden">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
            >
              {!isEng ? <h2>หน้าแรก</h2> : <h2>Home</h2>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
            >
              {!isEng ? <h2>เกี่ยวกับเรา</h2> : <h2>About us</h2>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/products"
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
            >
              {!isEng ? <h2>ผลิตภัณฑ์</h2> : <h2>Product</h2>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
            >
              {!isEng ? <h2>ติดต่อเรา</h2> : <h2>Contact</h2>}
            </NavLink>
          </li>
          <li>
            <button onClick={changeLanguage} className="lg:hidden">
              {!isEng ? (
                <div
                  className={{ isEng } ? inactiveClassName : activeClassName}
                >
                  ไทย
                </div>
              ) : (
                <div
                  className={{ isEng } ? activeClassName : inactiveClassName}
                >
                  EN
                </div>
              )}
            </button>
          </li>
        </ul>
      )}

      <ul className="hidden lg:flex items-center items-center justify-center text-center">
        <nav className="bg-navy p-4 flex justify-center bg-gray-200 bg-contain bg-local">
          <ul className="flex items-center">
            <li className="mr-20">
              <NavLink
                end
                to="/"
                className={({ isActive }) =>
                  isActive ? activeClassName : inactiveClassName
                }
              >
                {!isEng ? <p>หน้าแรก</p> : <p>Home</p>}
              </NavLink>
            </li>
            <li className="mr-20">
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? activeClassName : inactiveClassName
                }
              >
                {!isEng ? <p>เกี่ยวกับเรา</p> : <p>About us</p>}
              </NavLink>
            </li>
            <li className="mr-20">
              <NavLink
                to="/products"
                className={({ isActive }) =>
                  isActive ? activeClassName : inactiveClassName
                }
              >
                {!isEng ? <p>ผลิตภัณฑ์</p> : <p>Product</p>}
              </NavLink>
            </li>
            <li className="mr-20">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? activeClassName : inactiveClassName
                }
              >
                {!isEng ? <p>ติดต่อเรา</p> : <p>Contact</p>}
              </NavLink>
            </li>
            <li>
              <li className="mr-20 ">
                <button onClick={changeLanguage}>
                  <div className="flex text-2xl">
                    <p className={!isEng ? inactiveClassName + " text-red-600" : activeClassName}>
                      EN
                    </p>
                    <div  >&nbsp; | &nbsp;</div>
                    <p className={!isEng ? activeClassName : inactiveClassName+ " text-red-600"}>
                      ไทย
                    </p>
                  </div>
                </button>
              </li>
            </li>
          </ul>
        </nav>
      </ul>
    </nav>
  );
};

export default NavigationBar;
