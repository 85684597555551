import { React, useState, useEffect } from "react";
import "./homepage.css";
import ProductShowcase from "./components/productshowcase";

const showcase_project = [
  {
    name: `Conveyer/Customize`,
    des: [
      `สายพานลำเลียง`,
      `เครื่องลพเลียงสินค้าแบบสายพาน`,
      `เพื่อลำเลียงสินค้าไปอีกที่หนึ่ง`,
      `ลดการใช้แรงงานคน`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FConveyer-Customize.webp?alt=media&token=d26ab69d-ee49-41fc-9e7f-1fd34e531b00`,
    width_card: 350,
  },
  {
    name: `Vibrated Conveyer`,
    des: [
      `สำหรับ Bulk Material`,
      ` ชนิดผง เมล็ด เกล็ดต่าง ๆ`,
      `ครีมเทียม นมผง แป้ง `,
      `เกล็ดขนมปัง ผงปรุงรส`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FVibrater%20conveyer.webp?alt=media&token=9ada9027-efd2-4f9f-93ab-9c14a57207ed`,
    width_card: 350,
  },
  {
    name: `AGV Robot/Customize`,
    des: [
      `Automated Guided`,
      `Vehicle (AGV)`,
      `หุ่นยนต์อัตโนมัติในการบริการจัด`,
      `การสินค้าไม่จำเป็นต้องมีผู้ควบคุม`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FAGV-robotCustomize.webp?alt=media&token=aec1e1ea-e85a-4566-aaf3-4db2961a9691`,
    width_card: 350,
  },
  {
    name: `Pelletizer`,
    des: [
      `หุ่นยนต์ลำเลียงพาเลทอัตโนมัติ`,
      `ช่วยจัดเรียงสินค้าขึ้นพาเลท`,
      `เพื่อความรวดเร็ว`,
      `และแม่นยำในการบรรจุ`,
    ],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2Fpalletizer.webp?alt=media&token=0d6df4d0-4d17-416b-b890-16baf80c297a`,
    width_card: 350,
  } ,
  {
    name: `Maker Machines`,
    des: [`เครื่องคัดแยกแสกนด้วยบาร์โค้ด`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2FMaker%20machines.webp?alt=media&token=bab78e6a-e522-4244-a84b-7c9b205fef68`,
    width_card: 350,
  },
  {
    name: `SCADA`,
    des: [`ระบบการส่งข้อมูลในระยะไกล`,`เพื่อใช้ตรวจสอบเก็บข้อมูล`,`วิเคราะห์ข้อมูลแบบเรียลไทม์`,`และควบคุมการผลิตต่างๆ`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2FSCADA.webp?alt=media&token=e2a23291-9829-49be-84ea-c976e4b167c4`,
    width_card: 350,
  },
  {
    name: `Loadcell`,
    des: [`สำหรับใช้ในการบีบอัดในแนวตั้ง`,` หรือโหลความตึง`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2Floadcell.webp?alt=media&token=df15d21b-939a-47fb-9659-c8cb4b7feebe`,
    width_card: 350,
  },
  {
    name: `Weight Module`,
    des: [`สำหรับแท่นรองรับพื้น, ถัง, โซโล,`,`กรวยจ่าย อุปกรณ์สำหรับลำเลียง`,`เครื่องชั่งอื่นใดที่กำหนดเอง`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2Fweight%20module.webp?alt=media&token=6b910345-cb60-4fae-b035-632f7de21553`,
    width_card: 350,
  },
  {
    name: `Load Sensor`,
    des: [ `โมดูลชั่งน้ำหนัก WMS`, `สามารถประหยัดพื้นที่เครื่อง` ,`และเพิ่มปริมาณงานในการผลิต`
      ,`และการควบคุม`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2FLoad%20sensor.webp?alt=media&token=5219d425-0b45-4f66-8160-931a20c944e6`,
    width_card: 350,
  },
  {
    name: `ACT 350`,
    des: [ `ตัวส่งสัญญาณเครื่องชั่งน้ำหนัก`,
      `ACT350 Precision ของ`, `Mettler Toledo`,
      `เพื่อให้การชั่งอัตโนมิตแม่นยำ`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2FACT%20350.webp?alt=media&token=cbaa6d82-16ed-4cb3-a06f-af8cc7f390a4`,
    width_card: 350,
  },
  
  {
    name: `Laser Marker`,
    des: [`ระบบการมาร์กด้วยเลเซอร์และใช้`,
      `เลเซอร์ในการมาร์กเครื่องหมาย`,
      `บนผลิตภัณฑ์ โค้ดขัอมูล โลโก้ ` ,`ลงบนชิ้นงานโดยตรง`],
    picture_path: `https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/product%2FLaser%20Marker.webp?alt=media&token=f5bb8445-33f6-4c0e-a6af-e50ee9ecd4bd`,
    width_card: 350,
  },
];
 

function Productpage() {
  const [isEng, setIsEng] = useState(null);
  useEffect(() => {
    const storedIsEng = localStorage.getItem("isEng");
    if (storedIsEng !== null) {
      setIsEng(storedIsEng === "true"); // Convert string to boolean
    }
  }, []);
  return (
    <div className="productpage ">
      <div className="mx-4 page-info-text-content text-center items-center flex flex-col ">
        <div className="mx-10 flex-col mt-10">
          <div className="snap-center  lg:text-4xl md:text-2xl sm:text-2xl whitespace-pre mx-10 ">
            {!isEng ? (
              <h2>งานระบบ และผลิตภัณฑ์</h2>
            ) : (
              <h2>System and Product work</h2>
            )}
          </div>
          <div className="line my-10 mx-auto w-96"></div>

          <div className="snap-center mt-5 lg:text-3xl md:text-2xl sm:text-xl text-center whitespace-pre-line">
            {!isEng ? (
              <p4>
                <p4 className="font-bold ">รับออกแบบ</p4> ผลิต
                และติดตั้งระบบสายพานลำเลียง สายพานอัตโนมัติ ทุกขนาด ทุกแบบ
                และยินดีให้คำปรึกษารูปแบบ รุ่น
                และวัสดุที่ใช้ในการผลิตเพื่อสะดวกและเหมาะสมกับการใช้งานในแบบต่าง
                ๆ ทั้งลักษณะและจำนวนการผลิตของลูกค้า
              </p4>
            ) : (
              <p4>
                <p4 className="font-bold ">Design</p4>, manufacture and install
                conveyor belt systems. Automatic belts of every size, every
                style, and are happy to give advice on styles, models, and
                materials used in production to be convenient and suitable for
                various applications, both the nature and the amount of
                production of customers.
              </p4>
            )}
            <div className="mt-4 mx-auto w-96"></div>
            {!isEng ? (
              <p>
                รวมไปถึงระบบไฟ ตู้คอลโทรล รางไฟ เครื่องมือต่าง ๆ
                ที่ใช้ภายในโรงงานเพื่อสร้างความพึงพอใจ
                ช่วยให้ธุรกิจของลูกค้าเดินหน้าและเติบโตได้อย่างต่อเนื่องด้วยประสิทธิภาพสูงสุด
              </p>
            ) : (
              <p>
                Including the electrical system, control cabinet, light rail,
                various tools used within the factory to create satisfaction.
                Help customers' businesses move forward and grow continuously
                with maximum efficiency.
              </p>
            )}
          </div>
        </div>
      </div>
      {/* Product Show Case */}
      <div className="product-showcase text-xl text-center sm:ml-10 lg:ml-28 w-full">
        <ProductShowcase products={showcase_project} /> 
        {/* ABB */}
      
      </div>
      <div className="abb-banner bg-cover">
        <img
          className="abb-banner-pic w-full"
          src="https://firebasestorage.googleapis.com/v0/b/gsw-web.appspot.com/o/home%2FBanner%20%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%88%E0%B8%B3%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%20ABB.webp?alt=media&token=e8bc490a-1917-411a-8625-d96eed484537"
          alt="ตัวแทนจำหน่ายสินค้า ABB, Robot Arm, หุ่นยนต์อุตสาหกรรม" 
          lazyLoad="true"
        />
      </div>
    </div>
  );
}

export default Productpage;
