import React from 'react';




const ProductCard = ({ name, des, picturePath, widthCard }) => {
  return (
    <div className="max-w-sm mx-5 rounded overflow-hidden place-items-center shadow-lg m-2 snap-center" style={{ width: widthCard }}>
      <img className="w-96 object-cover" src={picturePath} alt={name} />
      <div className="px-6 py-4">
        <div className=" whitespace-pre-line font-bold text-xl mb-2"><h5>{name}</h5></div>
        <p className="text-gray-700 text-base text-center whitespace-pre-line">{des[0]}</p>
        <p className="text-gray-700 text-base text-center whitespace-pre-line">{des[1]}</p>
        <p className="text-gray-700 text-base text-center whitespace-pre-line">{des[2]}</p>
        <p className="text-gray-700 text-base text-center whitespace-pre-line">{des[3]}</p>
      </div>
    </div>
  );
};


const ProductGrid = ({ products }) => {
  return (
    <div className="lg:flex md:flex-wrap">
      {products.map((product, index) => (
        <ProductCard
          key={index}
          name={product.name}
          des={product.des}
          picturePath={product.picture_path}
          width_card={products.width_card}
        />
      ))}
    </div>
  );
};

const ProductShowcase = ({ products }) => {
  return (
    <div className="flex justify-center mx-6 my-4">
      <div className=" ">
        <ProductGrid products={products} />
      </div>
    </div>
  );
};

export default ProductShowcase;