// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNVHgU3O2je1V03k7sGt3bY26QkyRHF1M",
  authDomain: "gsw-web.firebaseapp.com",
  projectId: "gsw-web",
  storageBucket: "gsw-web.appspot.com",
  messagingSenderId: "228666746359",
  appId: "1:228666746359:web:5c573a88a95131415ffaf2",
  measurementId: "G-27MLSC9GMV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);