import React, { useEffect } from 'react';
import { Route ,Link } from 'react-router-dom';
 
 

function Error() {
  useEffect(() => {
    // Set the status code to 404 when the component mounts
    document.title = '404 Not Found'; 
    document.querySelector('meta[name="description"]').setAttribute('content', 'Page not found');
    //document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex, nofollow');
    //document.querySelector('meta[name="googlebot"]').setAttribute('content', 'noindex, nofollow');
    //document.querySelector('meta[property="og:title"]').setAttribute('content', '404 Not Found');
    //document.querySelector('meta[property="og:description"]').setAttribute('content', 'Page not found');
    //document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
    //document.querySelector('meta[property="og:image"]').setAttribute('content', 'your-404-image-url.jpg');
    //document.querySelector('meta[property="og:image:alt"]').setAttribute('content', '404 Not Found Image');
    
    // Optionally, you can log the error or send it to an error tracking service
    console.error('Page not found:', window.location.href);
  }, []);
  return (<div className="flex items-center justify-center h-96 bg-white-600 text-blue-600">
  <div className="text-center">
    <h1 className="text-9xl font-bold">404</h1>
    <p className="text-2xl mt-4">Not Found</p>
    <p className="mt-4">This page does not exist.</p>
    <div className="my-10">
      <Link to="/"  className="bg-blue-600 border-2 text-white px-4 py-2 mt-4 rounded-lg hover:bg-white hover:border-1 border-blue-600 hover:text-blue-600" >
        Go Home
      </Link>
    </div>
    <p className="mt-4 text-sm">Or wait a moment... you'll be redirected.</p>
    
  </div>
  
</div>);
}

export default Error